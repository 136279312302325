<template>
  <div>
    <vx-card class="mb-4">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <div class="flex w-full grid-cols-2 gap-2 align-baseline">
            <div class="w-1/3">
              <vs-input
                id="data_access_owner_id"
                class="w-full required"
                v-validate="'required|numeric|max:16'"
                :label="$t('data_access.owner_id')"
                v-model="model.owner_id"/>
              <span class="text-danger text-sm" v-show="errors.has('owner_id')">{{errors.first('owner_id')}}</span>
              </div>
            <vs-input
              id="data_access_owner_type"
              class="w-full required"
              v-validate="'required|max:191'"
              :label="$t('data_access.owner_type')"
              v-model="model.owner_type"/>
            <span class="text-danger text-sm" v-show="errors.has('owner_type')">{{errors.first('owner_type')}}</span>
          </div>
          <div class="flex w-full grid-cols-2 gap-2 align-baseline">
            <div class="w-1/3">
              <vs-input
                id="data_access_model_id"
                class="w-full required"
                v-validate="'required|numeric|max:16'"
                :label="$t('data_access.model_id')"
                v-model="model.model_id"/>
              <span class="text-danger text-sm" v-show="errors.has('model_id')">{{errors.first('model_id')}}</span>
            </div>
            <vs-input
              id="data_access_model_type"
              class="w-full required"
              v-validate="'required|max:191'"
              :label="$t('data_access.model_type')"
              v-model="model.model_type"/>
            <span class="text-danger text-sm" v-show="errors.has('model_type')">{{errors.first('model_type')}}</span>
          </div>
          <div class="flex w-full grid-cols-2 gap-2 align-baseline">
            <div class="w-1/3">
              <vs-input
                id="data_access_level"
                class="w-full"
                v-validate="'max:16'"
                :label="$t('data_access.level')"
                v-model="model.level"/>
              <span class="text-danger text-sm" v-show="errors.has('level')">{{errors.first('level')}}</span>
            </div>
          </div>
        </div>
        <div class="flex w-full align-baseline">
          <select-suggestion
            :max="20"
            :class="institution ? 'flex-grow' : 'flex-grow'"
            column="name"
            model="Institution"
            :label="$t('fields.institution')"
            v-model="institution"
            :appendClearOption="true"
            ref="select_institution"
            placeholderText="Digite o nome da instituição"/>
        </div>
        <div class="w-full flex gap-2 justify-end">
          <vs-button
            v-if="!isEdit()"
            :disabled="!validateForm"
            v-data_access="'data_access.create'"
            @click="createOrUpdate">
            {{ $t('action.save') }}
          </vs-button>
          <vs-button
            v-if="isEdit()"
            :disabled="!validateForm"
            v-data_access="'data_access.edit'"
            @click="createOrUpdate">
            {{ $t('action.save') }}
          </vs-button>

          <vs-button
            type="border"
            @click="cancel">
            {{ $t('common.back') }}
          </vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

import DataAccessService from '@/services/api/DataAccessService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    model: {
      id: null,
      owner_id : '',
      owner_type: '',
      model_id: null,
      model_type: '',
      level: ''
    },
    institution: {
      id: null,
      name: ''
    },
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.model.owner_id)
            && !this.isEmpty(this.model.owner_type)
            && !this.isEmpty(this.model.model_id)
            && !this.isEmpty(this.model.model_type)
    }
  },
  beforeMount() {
    this.service = DataAccessService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model.owner_id = response.owner_id
          this.model.owner_type = response.owner_type
          this.model.model_id = response.model_id
          this.model.model_type = response.model_type
          this.model.level = response.level
          this.institution.id = response.institution_id
          this.institution.name = response.institution_name
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
        id: this.id,
        owner_id: this.model.owner_id,
        owner_type : this.model.owner_type,
        model_id : this.model.model_id,
        model_type: this.model.model_type,
        level: this.model.level,
        institution_id: this.institution.id,
      }

      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.$router.push(`/data_access/${data.id}/edit`)
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    cancel() {
      this.$router.push('/data_access')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    }
  }
}
</script>

<style>

</style>
